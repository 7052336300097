import { DataStore } from '@aws-amplify/datastore';
import { Code as CodeModel, Page, Link } from '../models';
import { useEffect, useState } from 'react';
import { YouTube, Instagram, MobileScreenShare, DirectionsCar } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

export const Code = ({ params }) => {
    const [page, setPage] = useState()

    useEffect(() => {
      return async () => {
        const codeResponse = await DataStore.query(CodeModel, c => c.url_value("eq", params.code));
        const pageResponse = await DataStore.query(Page, codeResponse[0].codePageId)
        const linkResponse = await DataStore.query(Link, l => l.pageID == pageResponse.id)
        const formattedLinks = linkResponse.map(({id, display_name, destination_url, icon}) => ({id, display_name, destination_url, icon}))
        const page = {
            id: pageResponse.id,
            vehicle: {
                year: pageResponse.Vehicle.year,
                color: pageResponse.Vehicle.color,
                make: pageResponse.Vehicle.make,
                model: pageResponse.Vehicle.model,
                trim: pageResponse.Vehicle.trim,
            },
            links: formattedLinks
        }
        setPage(page)
      }
    }, [])

    const generateVehicleString = (vehicle) => `${vehicle.year} ${vehicle.color} ${vehicle.make} ${vehicle.model} ${vehicle.trim}`

    const getIcon = (name) => {
      switch (name) {
        case 'instagram':
          return <Instagram />
        case 'youtube':
          return <YouTube />
        default:
          return <MobileScreenShare />
      }
    }

    return (
      <header className="App-header">
        <p>
          Oh cool, you scanned a code!
        </p>
        <p>
          It looks like you scanned: {params.code}.
        </p>
        {!page && <p>Loading...</p>}
        {page && (
          <>
            <DirectionsCar />
            <h3>{generateVehicleString(page.vehicle)}</h3>
            <Stack spacing={2}>
              {page.links.map(l => (
                <Button
                  key={l.id}
                  variant='contained'
                  aria-label={`${l.icon}: ${l.display_name}`}
                  startIcon={getIcon(l.icon)}
                  onClick={() => {window.location.href = l.destination_url}}
                >{l.display_name}</Button>
              ))}
            </Stack>
          </>
        )}
      </header>
    )
}