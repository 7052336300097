import logo from './logo.svg';
import './App.css';
import { DataStore } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Route } from "wouter";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Code } from './components/Code';

// Amplify.configure(awsconfig);
// DataStore.configure(awsconfig)

function App() {
  return (
    <div className="App">
      <Route path="/">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <p>CarCode.io</p>
        </header>
      </Route>
      <Route path="/c/:code" component={Code} />
    </div>
  );
}

export default App;
