// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Page, Link, Vehicle, Code } = initSchema(schema);

export {
  Page,
  Link,
  Vehicle,
  Code
};